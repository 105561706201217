<template>
  <v-dialog v-model="isShow" max-width="800px" persistent>
    <v-card>
      <v-card-title>ระบุผู้มีส่วนเกี่ยวข้อง</v-card-title>
      <v-divider></v-divider>

      <v-container fluid color="primary">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            style="max-height: 500px; overflow-y: scroll; padding-left: 24px"
          >
            <v-checkbox
              color="primary"
              v-model="tempSelected"
              v-for="(item, index) in layerList"
              :key="index"
              :label="item.Name"
              :value="item.Alternate"
              hide-details
            >
              <template v-slot:label>
                <div>
                  <b>{{ item.Name }}</b
                  ><br />
                  <span style="color: #8e8e8e; font-size: 14px">
                    mobility : {{ item.Mobility }}, vertical : {{ item.Vertical }},
                    timescale : {{ item.Timescale }}, spatial : {{ item.Spatial }}</span
                  >
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <v-btn color="primary" outlined large @click="close()"> ปิด </v-btn>
        <v-btn width="20%" color="primary" depressed large dark @click="saveLayer()">
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LayerModal",
  data() {
    return {
      existLayer: [],
      tempSelected: [],
      selectedLayer: [],
    };
  },
  props: {
    isShow: Boolean,
    layerList: Array,
  },
  mounted() {
    // this.$root.$on('existLayer', this.clearData)
  },
  methods: {
    clearData() {
      this.tempSelected = [];
      this.existLayer = [];
      this.selectedLayer = [];
    },
    close() {
      this.tempSelected = [...this.existLayer];
      this.$emit("close");
    },
    saveLayer() {
      this.existLayer = [...this.tempSelected];
      this.selectedLayer = [...this.tempSelected];
      this.$emit("selected", this.selectedLayer);
      this.$emit("close");
    },
  },
};
</script>
