<template>
  <div class="example">
    <apexchart
      ref="matrixChart"
      width="100%"
      height="600"
      type="heatmap"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "MatrixChart",
  props:{
    matrixElement:{
      type: Object,
      default: ()=>{},
    }
  },
  data: function () {
    return {
      chartOptions: {
        dataLabels: {
          enabled: true,
        },
        colors: ["#008FFB"],
        plotOptions: {
              heatmap: {
                enableShades: false,
                colorScale: {
                  ranges: [
                    {
                      from: 0,
                      to: 0,
                      color: '#eef2ff'
                    },  
                    {
                      from: 1,
                      to: 500,
                      color: '#c7d2fe'
                    },
                    {
                      from: 501,
                      to: 2000,
                      color: '#818cf8'
                    },
                    {
                      from: 2001,
                      to: 5000,
                      color: '#4338ca'
                    },
                    {
                      from: 5001,
                      to: 50000,
                      color: '#312e81'
                    },
                  ],
                },
            
              }
            },
        xaxis: {
          type: "category",
          
        },
        
        title: {
          text: "MUC Matrix",
        },
      },
      series: [
        
      ],
      maxLevel: 0
    };
    
  },
  created(){
    const count = this.matrixElement.matrix.length
    this.series = []
    for(let i= 0 ; i<count ; i++){
      let serie = []
      for(let j= 0 ; j<count ; j++){
        serie.push({
          x:this.matrixElement.axis[j],
          y:this.matrixElement.matrix[count-i-1][j]
        })
        if(this.maxLevel< this.matrixElement.matrix[count-i-1][j]){
          this.maxLevel = this.matrixElement.matrix[count-i-1][j]
        }
      }
      this.series.push(
        { name: this.matrixElement.axis[count-i-1],
          data: serie
        }
      )
    }
  },
  watch:{
      series(){
        this.updateChart()
      }
    },
  methods: {
    generateData(count, yrange) {
      var i = 0;
      var series = [];
      while (i < count) {
        var x = (i + 1).toString();
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        series.push({
          x: x,
          y: y,
        });
        i++;
      }
      console.log(series);
      return series;
    },
    updateChart(){
        this.$refs.matrixChart.updateOptions({
          plotOptions: {
            heatmap: {
            colorScale: {
                    ranges: [
                    {
                      from: 0,
                      to: 0,
                      color: '#eef2ff',
                      name:'ไม่มี'
                    },  
                    {
                      from: 1,
                      to: this.maxLevel*0.2,
                      color: '#c7d2fe',
                      name:'น้อยมาก'
                    },
                    {
                      from: this.maxLevel*0.2,
                      to: this.maxLevel*0.4,
                      color: '#818cf8',
                      name:'น้อย'
                    },
                    {
                      from: this.maxLevel*0.4,
                      to: this.maxLevel*0.6,
                      color: '#4338ca',
                      name:'ปานกลาง'
                    },
                    {
                      from: this.maxLevel*0.6,
                      to: this.maxLevel*0.8,
                      color: '#312e81',
                      name:'มาก'
                    },
                    {
                      from: this.maxLevel*0.8,
                      to: this.maxLevel,
                      color: '#161459',
                      name:'มากที่สุด'
                    },
                    ],
                  },
            }
          }
        })
        if(screen.width<600){
          this.$refs.matrixChart.updateOptions({
            yaxis: {
              labels: {
                show: true,
                maxWidth: 50,
              },
            },
          })
        }
      }
  },
};
</script>
