<template>
  <v-app light>
    <GeomDetail
      :isShow="isGeomVisible"
      :geomDetail="geomDetail"
      @close="closeGeomDetailModal"
    />
    <LayerModal
      ref="tempSelected"
      :isShow="isModalVisible"
      :layerList="layerList"
      @selected="selectedLayer"
      @close="closeModal"
    />
    <v-main>
      <div class="container">
        <v-card color="primary" class="header pa-4 my-2 d-flex flex-row" elevation="0">
          <div class="mr-3">
            <v-icon large color="white"> mdi-waves </v-icon>
          </div>
          <div class="d-flex flex-column">
            <h2>Maritime Use Conflicts Analysis</h2>
            <div>
              {{ title[selectedProcess] }}
            </div>
          </div>
        </v-card>

        <!-- process 1 -->
        <!-- Case study selection -->
        <v-card
          v-if="selectedProcess === 0"
          class="d-flex flex-row pa-4 pa-md-12 mb-4"
          elevation="0"
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-card
                v-for="item in layerCaseStudyPlace"
                :key="item.ID"
                class="d-flex flex-row pa-2 mb-4"
                elevation="2"
              >
                <img
                  width="40%"
                  v-bind:src="
                    'https://mmmap15.longdo.com/mmmap/snippet/?map=epsg3857&locale=th&HD=1&height=250&width=400&lat=' +
                    item.Lat +
                    '&long=' +
                    item.Lon +
                    '&zoom=' +
                    item.zoom
                  "
                  alt=""
                />

                <div class="d-flex flex-column pa-6">
                  <h2 class="my-3">{{ item.title }}</h2>
                  <div style="width: 24px; height: 4px; background-color: #61a4bc"></div>
                  <div class="subcolor--text sub-title-card my-6">
                    {{ item.description }}
                  </div>

                  <v-btn
                    @click="getMUClayer(item.GeoCode, item.Lat, item.Lon)"
                    width="30%"
                    depressed
                    color="primary"
                    large
                    dark
                  >
                    เลือก <v-icon right> mdi-arrow-right-thin </v-icon></v-btn
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <!-- process 2 -->
        <v-card
          v-if="selectedProcess === 1"
          class="d-flex flex-row pa-6 mb-4"
          elevation="0"
        >
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <div>
                <a @click="backToSelectPlace()">&#60; ย้อนกลับไปเลือกสถานที่ใหม่</a>
              </div>
              <br />
              <v-card class="d-flex flex-column pa-0 mb-4" elevation="2">
                <v-card class="py-2 px-4 rounded-b-0" color="btncolor" dark elevation="0">
                  <h3>ระบุผู้มีส่วนเกี่ยวข้องกับปัญหา</h3>
                  <div>
                    มีทั้งหมด {{ layerList.length }} ชั้นข้อมูล - ถูกเลือก
                    {{ selectedLayerList.length }} ชั้นข้อมูล
                  </div>
                </v-card>

                <div class="d-flex flex-column pa-6">
                  <div class="d-flex justify-end">
                    <v-btn
                      @click="showModal()"
                      class="mb-4"
                      width="40%"
                      depressed
                      color="btncolor"
                      large
                      :dark="!isLoading"
                      :disabled="isLoading"
                      ><v-icon left> mdi-cog-outline </v-icon
                      >{{ isLoading ? "กำลังดึงชั้นข้อมูล" : "เลือกชั้นข้อมูล" }}</v-btn
                    >
                  </div>
                  <p><b>หัวข้อปัญหา</b></p>
                  <v-text-field
                    v-model="textTitle"
                    label="โปรดระบุปัญหา"
                    single-line
                    filled
                  ></v-text-field>
                  <p><b>รายละเอียดความขัดแย้ง</b></p>
                  <v-textarea
                    v-model="textDescription"
                    label="โปรดระบุรายละเอียด"
                    single-line
                    filled
                    maxlength="120"
                    full-width
                  ></v-textarea>
                  <div class="d-flex justify-end">
                    <v-btn
                      @click="matrixAnalyze()"
                      class="mb-4"
                      width="40%"
                      depressed
                      color="btncolor"
                      large
                      :dark="selectedLayerList.length !== 0"
                      :disabled="selectedLayerList.length === 0"
                      >Run Analysis <v-icon right> mdi-arrow-right-thin </v-icon></v-btn
                    >
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="min-height">
              <longdo-map @load="init" />
            </v-col>
          </v-row>
        </v-card>

        <!-- process 3: result hexagon -->
        <v-card
          v-if="selectedProcess === 2"
          class="d-flex flex-column pa-6 mb-4"
          elevation="0"
        >
          <v-progress-linear
            :active="isLoadingDrawMatrix"
            indeterminate
            absolute
            top
          ></v-progress-linear>
          <div>
            <a @click="backToSetting()">&#60; ย้อนกลับไปตั้งค่าใหม่</a>
          </div>
          <br />
          <v-row>
            <v-col cols="12" sm="9" md="9" class="min-height">
              <longdo-map @load="init" />
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-btn
                @click="downloadGeojson()"
                class="mb-4"
                width="100%"
                depressed
                color="success"
                large
                :disabled="collection === {}"
                >Download Data<v-icon right> mdi-download </v-icon></v-btn
              >
              <v-btn
                @click="downloadXML()"
                class="mb-4"
                width="100%"
                depressed
                color="success"
                large
                >Download XML<v-icon right> mdi-download </v-icon></v-btn
              >
              <v-card class="d-flex flex-column pa-0 mb-4 mt-0" elevation="2">
                <v-card class="py-2 px-4 rounded-b-0" color="subcolor" dark elevation="0">
                  <h3>รายละเอียด</h3>
                </v-card>
                <div class="pa-6">
                  <h3>MUC Score</h3>
                  <p style="font-size: 16px">
                    คะแนนรวม : {{ numberWithCommas(totalScore) }}<br />พื้นที่ :
                    {{ numberWithCommas(totalArea) }} ตร.กม.
                  </p>
                  <h3>สัญลักษณ์</h3>
                  <p style="font-size: 14px">ร้อยละของชั้นข้อมูลที่มีการซ้อนทับ</p>
                  <div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(44, 123, 182, 0.7)'"
                      ></Hexagon>
                      <span>0%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(0, 166, 202, 0.7)'"
                      ></Hexagon>
                      <span>1% - 12%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(0, 204, 188, 0.7)'"
                      ></Hexagon>
                      <span>13% - 25%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(144, 235, 157, 0.7)'"
                      ></Hexagon>
                      <span>26% - 37%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(255, 255, 140, 0.7)'"
                      ></Hexagon>
                      <span>38% - 50%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(249, 208, 87, 0.7)'"
                      ></Hexagon>
                      <span>51% - 62%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(242, 158, 46, 0.7)'"
                      ></Hexagon>
                      <span>63% - 75%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(231, 104, 24, 0.7)'"
                      ></Hexagon>
                      <span>76% - 87%</span>
                    </div>
                    <div>
                      <Hexagon
                        class="vertical-bottom"
                        :color="'rgba(215, 25, 28, 0.7)'"
                      ></Hexagon>
                      <span>88% - 100%</span>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="min-height: 600px">
            <v-col cols="12" sm="12" md="12">
              <v-card class="d-flex flex-column pa-0 mb-4" elevation="2">
                <v-card class="py-2 px-4 rounded-b-0" color="subcolor" dark elevation="0">
                  <h3>ผลลัพธ์</h3>
                </v-card>
                <div class="pa-6">
                  <MatrixChart v-if="resp_ready" :matrixElement="matrix_resp" />
                  <div v-else>ไม่มีข้อมูลความขัดแย้ง</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import LayerModal from "@/components/msp/LayerModal.vue";
import GeomDetail from "@/components/msp/GeomDetail.vue";
import MatrixChart from "@/components/msp/MatrixChart.vue";
import Hexagon from "@/components/Svg/Hexagon.vue";
export default {
  name: "Matrix",
  data: () => ({
    title: [
      "ขั้นตอนที่ 1: เลือกพื้นที่ที่ต้องการวิเคราะห์ (Case Study selection)",
      "ขั้นตอนที่ 2: เลือกชั้นข้อมูลที่ต้องการตั้งค่า (Case Study Setup)",
      "ผลลัพธ์: รายละเอียดผลลัพธ์และการคืนค่า MUC Scores",
    ],
    textTitle: "",
    textDescription: "",
    totalArea: 0,
    selectedProcess: 0,
    layerList: [],
    selectedLayerList: [],
    isModalVisible: false,
    isGeomVisible: false,
    locGoto: {},
    map: undefined,
    geom_resp: {},
    matrix_resp: {},
    collection: {},
    resp_ready: false,
    isLoading: true,
    totalScore: 0,
    legendColor: [],
    layerCaseStudyPlace: [],
    geomDetail: [],
    isLoadingDrawMatrix: false,
  }),
  components: {
    LayerModal,
    GeomDetail,
    MatrixChart,
    Hexagon,
  },
  created() {
    let self = this;
    let url = process.env.VUE_APP_WEBSERVICE + "/get/mucList";
    self.axios.get(url).then((response) => {
      self.layerCaseStudyPlace = response.data.data;
    });
  },
  mounted() {},

  methods: {
    init(map) {
      let self = this;
      self.map = map;
      // self.map.Layers.add(
      //   new window.longdo.Layer("", {
      //     type: window.longdo.LayerType.TMS,
      //     url: process.env.VUE_APP_THAILAND_SATELLITE,
      //     zoomRange: { min: 1, max: 20 },
      //     source: { min: 1, max: 16 },
      //     zoomOffset: 0,
      //   })
      // );

      self.map.Event.bind("overlayClick", function (overlay) {
        if ("geomID" in overlay) {
          let url =
            process.env.VUE_APP_WEBSERVICE +
            "/get/geomdetail/" +
            overlay.geomID +
            `?layer=${self.selectedLayerList.join(",")}`;
          self.axios.get(url).then((response) => {
            self.geomDetail = response.data.data;
            self.showGeomDetailModal();
          });
        }
      });

      self.map.location(self.locGoto);
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showGeomDetailModal() {
      this.isGeomVisible = true;
    },
    closeGeomDetailModal() {
      this.isGeomVisible = false;
    },
    getMUClayer(geocode, lat, lon) {
      let self = this;
      console.log(geocode);
      let url = process.env.VUE_APP_WEBSERVICE + "/get/layer?geocode=" + geocode;

      self.selectedProcess = 1;
      window.scrollTo(0, 0);

      self.locGoto = { lat: lat, lon: lon };
      self.axios.get(url).then((response) => {
        self.layerList = response.data.data;
        self.isLoading = false;
      });
    },
    selectedLayer(val) {
      this.selectedLayerList = val;
      this.drawLayer(val);
    },
    drawLayer(layerArr) {
      let self = this;
      self.map.Layers.clear();
      setTimeout(() => {
        // this.map.Layers.add(
        //   new window.longdo.Layer("", {
        //     type: window.longdo.LayerType.TMS,
        //     url: process.env.VUE_APP_THAILAND_SATELLITE,
        //     zoomRange: { min: 1, max: 20 },
        //     source: { min: 1, max: 16 },
        //     zoomOffset: 0,
        //   })
        // );
        layerArr.forEach((name) => {
          self.map.Layers.add(
            new window.longdo.Layer(name, {
              type: window.longdo.LayerType.WMS,
              url: process.env.VUE_APP_GEOSERVER,
            })
          );
        });
      }, 200);
    },
    matrixAnalyze() {
      let self = this;
      let geom_val = "";

      if (self.map.Ui.Toolbar.measureList) {
        const geom_list = self.map.Ui.Toolbar.measureList()[0];
        if (geom_list) {
          geom_val = window.longdo.Util.overlayToWkt([geom_list]);
        }
      }
      self.isLoadingDrawMatrix = true;
      self.resp_ready = false;
      self.selectedProcess = 2;
      self.map.Layers.clear();
      let url_arr = [];
      let mobi = [];
      let timesc = [];
      let spat = [];
      let verti = [];
      if (self.selectedLayerList) {
        for (let i = 0; i < self.selectedLayerList.length; i++) {
          url_arr.push(self.selectedLayerList[i].replace("geonode:", ""));
          mobi.push(self.layerList[i].Mobility);
          timesc.push(self.layerList[i].Timescale);
          spat.push(self.layerList[i].Spatial);
          verti.push(self.layerList[i].Vertical);
        }
      }
      let map_url =
        process.env.VUE_APP_WEBSERVICE +
        `/get/muc?layer=${url_arr}&mobility=${mobi}&timescale=${timesc}&spatial=${spat}&vertical=${verti}&geom=${geom_val}`;

      self.axios.get(map_url).then((response) => {
        if (response.data.data.muc === null) {
          self.isLoadingDrawMatrix = false;
          return;
        }
        self.geom_resp = response.data.data.muc;
        self.totalArea = response.data.data.area;
        self.collection = response.data.data.collection;
        self.minScore = Math.min(...response.data.data.muc.map((o) => o.score));
        self.maxScore = Math.max(...response.data.data.muc.map((o) => o.score));
        for (let i = 0; i < self.geom_resp.length; i++) {
          const overlay = window.longdo.Util.overlayFromWkt(self.geom_resp[i].geom, {
            title: self.geom_resp[i].score.toLocaleString(),
            lineWidth: 1,
            lineColor: "rgba(255, 255, 255 , 0.5)",
            fillColor: self.checkColor(self.geom_resp[i].score, self.maxScore),
          });
          overlay[0].geomID = self.geom_resp[i].id;

          self.map.Overlays.add(overlay[0]);
          if (
            !self.legendColor.includes(
              self.checkColor(self.geom_resp[i].score, self.maxScore)
            )
          ) {
            self.legendColor.push(
              self.checkColor(self.geom_resp[i].score, self.maxScore)
            );
          }
          self.totalScore += self.geom_resp[i].score;
        }
        self.matrix_resp = response.data.data.matrix;
        self.resp_ready = true;
        self.isLoadingDrawMatrix = false;
      });
      self.map.resize();
    },
    downloadGeojson() {
      let text = JSON.stringify(this.collection);
      let filename = "muc_data.geojson";
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    downloadXML() {
      let text = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- pycsw 2.6.1 -->
<csw:GetRecordByIdResponse xmlns:csw="http://www.opengis.net/cat/csw/2.0.2" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:dct="http://purl.org/dc/terms/" xmlns:gmd="http://www.isotc211.org/2005/gmd" xmlns:gml="http://www.opengis.net/gml" xmlns:ows="http://www.opengis.net/ows" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/cat/csw/2.0.2 http://schemas.opengis.net/csw/2.0.2/CSW-discovery.xsd">
  <csw:Record>
    <dc:title>${this.textTitle}</dc:title>
    <dc:type>dataset</dc:type>
    <dc:subject></dc:subject>
    <dc:format>vector</dc:format>
    <dct:abstract>${this.textDescription}</dct:abstract>
    <dc:language>eng</dc:language>
    <dct:spatial scheme="http://www.opengis.net/def/crs">EPSG:4326</dct:spatial>
  </csw:Record>
</csw:GetRecordByIdResponse>
`;
      let filename = "muc_data.xml";
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    backToSelectPlace() {
      this.selectedProcess = 0;
      this.selectedLayerList = [];
      this.layerList = [];
      this.$refs.tempSelected.clearData();
    },
    backToSetting() {
      this.selectedProcess = 1;
      // const overlayList = this.map.Overlays.list();
      // for (const overlay of overlayList) {
      //   if (overlay.ignore) {
      //     continue;
      //   }
      //   this.map.Overlays.remove(overlay);
      // }
      this.drawLayer(this.selectedLayerList);
      this.map.Overlays.clear();

      this.totalScore = 0;
    },
    checkColor(score, max) {
      let ratio = score / max;
      if (ratio === 0) {
        return "rgba(44, 123, 182, 0.7)";
      } else if (ratio < 0.12) {
        return "rgba(0, 166, 202, 0.7)";
      } else if (ratio < 0.25) {
        return "rgba(0, 204, 188, 0.7)";
      } else if (ratio < 0.37) {
        return "rgba(144, 235, 157, 0.7)";
      } else if (ratio < 0.5) {
        return "rgba(255, 255, 140, 0.7)";
      } else if (ratio < 0.62) {
        return "rgba(249, 208, 87, 0.7)";
      } else if (ratio < 0.75) {
        return "rgba(242, 158, 46, 0.7)";
      } else if (ratio < 0.87) {
        return "rgba(231, 104, 24, 0.7)";
      } else {
        return "rgba(215, 25, 28, 0.7)";
      }
    },
    numberWithCommas(x) {
      x = x.toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  watch: {},
};
</script>

<style scoped>
.header {
  color: white;
}
#map {
  width: 100%;
  height: 100%;
}
#map-result {
  width: 100%;
  height: 100%;
}
.vertical-bottom {
  vertical-align: bottom;
  margin-right: 8px;
}
@media only screen and (max-width: 600px) {
  .min-height {
    min-height: 600px;
  }
}
</style>
