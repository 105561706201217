<template>
    <svg width="24px" height="24px">
        <polygon points="24,12 18,22.4 6,22.4 0,12 6,1.6 18,1.6"
        stroke="black" 
        :fill="color" stroke-width="1" />
    </svg>
</template>

<script>
export default {
  props: {
    color: {
        type:String,
        default: 'black'
    }
  }
};
</script>