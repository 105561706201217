<template>
  <v-dialog v-model="isShow" max-width="800px" style="z-index: 1000" persistent>
    <v-card>
      <v-card-title>ค่าคะแนนของชั้นข้อมูลที่ทับซ้อนในพื้นที่ที่เลือก</v-card-title>
      <v-divider></v-divider>

      <v-container fluid color="primary">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headers"
              :items="geomDetail"
              :items-per-page="10"
              class="elevation-1"
              hide-default-footer
              disable-pagination
            ></v-data-table>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="primary" outlined large @click="close()"> ปิด </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GeomDetail",
  data() {
    return {
      headers: [
        {
          text: "Layer1",
          align: "start",
          sortable: false,
          value: "layer1",
        },
        { text: "Layer2", align: "start", sortable: false, value: "layer2" },
        { text: "Score", align: "start", value: "score" },
      ],
    };
  },
  props: {
    isShow: Boolean,
    geomDetail: Array,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
